import httpClient from "../http-client";

const ENDPOINT = "/agentes-base";

export interface GetAgenteDto {
  id: number;
}

export interface CreateAgenteDto {
  id?: number;
  codigo: number;
  nombre: string;
}

class AgenteService {
  // es paginado
  /*
  getAll(): Promise<GetAgenteDto[]> {
    return httpClient.get(ENDPOINT).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }
  */

  save(createDto: CreateAgenteDto) {
    if (createDto.id) {
      return httpClient.put(`${ENDPOINT}/${createDto.id}`, createDto);
    } else {
      return httpClient.post(ENDPOINT, createDto);
    }
  }
}
export default new AgenteService();
