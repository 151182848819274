










































































import Vue from "vue";
import AgenteService from "@/api/agente/AgenteService";
import DateTimePicker from "@/components/shared/DateTimePicker.vue";
export default Vue.extend({
  props: ["dialog", "dato"],
  components: {
    DateTimePicker
    //VSelectize
  },
  data() {
    return {
      editedIndex: -1,
      editedItem: {
        codigo: 0,
        nombre: ""
      },
      nivelOptions: [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
        { text: "6", value: "6" },
        { text: "7", value: "7" }
      ],
      statusOptions: [
        { text: "Activo", value: "1" },
        { text: "Inactivo", value: "2" }
      ]
    };
  },
  mounted() {
    this.editedItem = { ...this.dato };
  },
  methods: {
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      AgenteService.save(this.editedItem)
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Plan",
            text: "Guardado exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Plan",
            text: "No se pudo crear"
          });
        });
    }
  },
  computed: {},
  watch: {}
});
