<template>
  <v-card>
    <v-card-title class="justify-center"> </v-card-title>
    <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title></v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <AgenteAdd
            v-if="dialog"
            @saved="fetchData()"
            :dialog="dialog"
            :dato="dato"
            @dialogChange="dialogChange"
          />

          <v-btn color="primary" dark class="mb-2" @click="newItem()">
            <v-icon left>
              mdi-plus
            </v-icon>
            Nuevo
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
      </template>
    </v-data-table>

    <v-pagination
      v-model="page"
      :length="pages"
      v-on:input="fetchData()"
    ></v-pagination>
  </v-card>
</template>

<script>
import axios from "axios";
import AgenteAdd from "@/components/agentes/AgenteAdd.vue";
export default {
  components: {
    AgenteAdd
  },
  data() {
    return {
      url: process.env.VUE_APP_API,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      headers: [
        { text: "codigo", align: "start", value: "codigo" },
        { text: "nombre", value: "nombre" },
        { text: "nivel", value: "nivel" },
        { text: "fecha ingreso", value: "fecha_ingreso" },
        { text: "fecha salida", value: "fecha_salida" },
        { text: "status", value: "status" },
        { text: "Acción", value: "actions", sortable: false }
      ],
      dialog: false,
      dato: {
        codigo: 0,
        nombre: ""
        // ignorar todo
        /*
        nivel: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        fecha_ingreso: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        fecha_salida: "",
        status: ""
        */
      }
    };
  },
  methods: {
    fetchData() {
      console.log("llamando api");
      //axios.get("data/datos.json").then(response => {
      axios
        .get(
          this.url + "/agentes-base/paginado?page=" + this.page + "&limit=10"
        )
        .then(response => {
          this.datos = response.data?.items || [];
          this.pages = response.data?.meta?.totalPages || 0;
        });
    },
    setDefaultItem() {
      const newDto = {
        codigo: 0,
        nombre: ""
        /*
        // ignorar todo
        nivel: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        fecha_ingreso: "",
        // eslint-disable-next-line @typescript-eslint/camelcase
        fecha_salida: "",
        status: ""
        */
      };
      this.dato = newDto;
    },
    editItem(item) {
      this.dato = { ...item };
      if (this.dato.fecha_ingreso) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.dato.fecha_ingreso = new Date(this.dato.fecha_ingreso);
      }
      if (this.dato.fecha_salida) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.dato.fecha_salida = new Date(this.dato.fecha_salida);
      }
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    dialogChange(value) {
      this.dialog = value;
    }
  },
  created() {
    this.fetchData();
  }
};
</script>
